.checkbox {
  --border: #bbbbc1;
  --border-hover: #9898a3;
  --border-active: #75a93a;
  height: 24px;
  cursor: pointer;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}

.checkbox svg {
  display: block;
  position: absolute;
}

.checkbox input + svg {
  width: 27px;
  height: 24px;
  left: 0;
  top: 0;
  color: var(--color, var(--border));
  transition: color 0.25s linear;
}

.checkbox input + svg .tick {
  stroke-dasharray: 20;
  stroke-dashoffset: var(--stroke-dashoffset, 20);
  transition: stroke-dashoffset 0.2s;
}

.checkbox input + svg .rect {
  fill-opacity: var(--fill-opacity, 0);
  transition: fill-opacity 0.25s linear;
}

.checkbox input + svg + svg {
  width: 13px;
  height: 13px;
  fill: none;
  stroke: var(--border-active);
  stroke-width: 1.25;
  stroke-linecap: round;
  top: -8px;
  left: 22px;
  stroke-dasharray: 4.5px;
  stroke-dashoffset: 13.5px;
  animation: var(--animation, none) 0.2s ease 0.175s;
}

.checkbox input:checked + svg {
  --color: var(--border-active);
  --stroke-dashoffset: 0;
  --fill-opacity: 1;
}

.checkbox input:checked + svg + svg {
  --animation: check;
}

.checkbox:hover input:not(:checked) + svg {
  --color: var(--border-hover);
}

@-webkit-keyframes check {
  100% {
    stroke-dashoffset: 4.5px;
  }
}

@keyframes check {
  100% {
    stroke-dashoffset: 4.5px;
  }
}
